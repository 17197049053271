import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@tcc-mono/shared/authentication/passport';

const routes: Routes = [
  {
    path: 'app',
    canActivate: [AuthGuard],
    loadChildren: () => import('@tcc-mono/coenen/web/home/feature').then(m => m.HomeModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('@tcc-mono/shared/authentication/passport').then(m => m.AuthenticationModule)
  },
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'app'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class CoenenWebShellShellRoutingModule { }
