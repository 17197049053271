import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedUiServiceModule } from '@tcc-mono/shared/ui/services';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { CoenenWebShellShellRoutingModule } from './coenen-web-shell-routing.module';
import { AnimationService, FontawesomeTypeService, LanguageService } from '@tcc-mono/shared/ui/theming';
import { AuthenticationModule } from '@tcc-mono/shared/authentication/passport';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isToday from 'dayjs/plugin/isToday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import 'dayjs/locale/nl';
import { FontawesomeCoreModule } from './fontawesome-core.module';

@NgModule({
  imports: [
    BrowserModule,
    CoenenWebShellShellRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    AuthenticationModule.forRoot(),
    SharedUiServiceModule,
    FontawesomeCoreModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    InAppBrowser
  ]
})
export class CoenenWebShellModule {
  public constructor(
    private readonly _animationService: AnimationService,
    private readonly _fontawesomeService: FontawesomeTypeService,
    private readonly _languageService: LanguageService
  ) {
    this._animationService.initTheme();
    this._fontawesomeService.initTheme();
    this._languageService.initLang();

    dayjs.locale('nl');
    dayjs.extend(isoWeek);
    dayjs.extend(isToday);
    dayjs.extend(isSameOrBefore);
  }
}
